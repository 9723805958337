import React, { useEffect, useState } from "react";
import useQuery from "../hooks/useQuery";
import axios from "axios";
import IdModal from "../components/Modals/IdModal";
import { CircularProgress, Snackbar } from "@mui/material";

const IdTemplate = () => {
  const query = useQuery();
  const [accountid, setAccountId] = useState();
  const userid = query.get("userid");
  const [appAccountId, setAppAccountId] = useState();
  const accountidURL = query.get("accountid");
  const objectid = query.get("objectid");
  let crmObjIds = query.get("crmObjIds");
  const [templates, setTemplates] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [accountDetails, setAccountDetails] = useState();

  function closeModal() {
    setModalIsOpen(false);
  }

  async function getAccountID() {
    const api = {
      url: `https://preprod-api.tbplabs.tech/chatapp/publicGetChatApps?accountId=${accountidURL}&userId=${userid}`
    };    
      const resp = await axios.get(api.url);
    setAccountId(resp?.data.apps[0].id);
    setAppAccountId(resp?.data.apps[0].appAccount_id);
    setAccountDetails(resp?.data);
  }

  useEffect(() => {
    getAccountID();
  }, [])
  

  crmObjIds = crmObjIds.split(",").map((id) => id);

  const getTemplates = async () => {
    try {
      if(appAccountId=== undefined)
      return;
      let url = `https://preprod-api.tbplabs.tech/chatapp/templates/nonToken?appAccountId=${appAccountId}`;
      let res = await axios.get(url);
      setTemplates(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getTemplates();
  }, [appAccountId]);

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleToastClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 6000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [success]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      {isLoading ? (
        <CircularProgress color="primary" />
      ) : (
        <button
          onClick={() => setModalIsOpen(true)}
          className="text-white bg-sky-600 p-2 rounded-lg"
        >
          Select Template
        </button>
      )}

      <div className="h-14 bg-white w-full absolute top-0 flex justify-end items-center">
      <select className="border mr-2 h-8 rounded-lg cursor-pointer text-sm text-sky-600 px-3 font-semibold" onChange={(e)=>setAppAccountId(e.target.value)}>
        {accountDetails?.apps.map((e)=>(
            <option value={e.appAccount_id}>{e.name}</option>
            ))}
        </select>
      </div>

      <IdModal
        appAccountId={appAccountId}
        openModal={modalIsOpen}
        closeModal={closeModal}
        templates={templates}
        crmObjIds={crmObjIds}
        setIsLoading={setIsLoading}
        setSuccess={setSuccess}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={success}
        onClose={handleToastClose}
        message="✅ Template submitted successfully"
        key={vertical + horizontal}
        severity="success"
      />
    </div>
  );
};

export default IdTemplate;
