import "./App.css";
import Header from "./components/Header/Header";
import SideBar from "./components/SideBar/SideBar";
import ChatArea from "./components/ChatArea/ChatArea";
import Profile from "./components/Profile/Profile";
import useQuery from "./hooks/useQuery";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import TemplateModal from "./components/Modals/TemplateModal";

const Layout = () => {
  const query = useQuery();

  const [accountid, setAccountId] = useState();
  const [appAccountId, setAppAccountId] = useState();

  const userid = query.get("userid");
  const objectid = query.get("objectid");
  const accountidURL = query.get("accountid");
  const ids = query.get("xid");
  const [profile, setProfile] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [accountDetails, setAccountDetails] = useState();

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  let tempId = useRef(0);

  useEffect(() => {
    tempId.current = profile;
  }, [profile]);

  async function getAccountID() {
    if (!accountidURL) {
      return;
    }

    const api = {
      url: `https://preprod-api.tbplabs.tech/chatapp/publicGetChatApps?accountId=${accountidURL}&userId=${userid}`,
    };

    try {
      const resp = await axios.get(api.url);
      setAccountId(resp?.data.apps[0].id);
      setAppAccountId(resp?.data.apps[0].appAccount_id);
      setAccountDetails(resp.data);
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  }

  useEffect(() => {
    getAccountID();
  }, []);

  useEffect(() => {
    console.log("yuvraj", appAccountId);
  }, [appAccountId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (appAccountId === undefined) return;
        const [profileResponse, templatesResponse] = await Promise.all([
          axios.get(
            `https://preprod-api.tbplabs.tech/chatapp/getObject?objectid=${objectid}&appAccountId=${appAccountId}`
          ),
          axios.get(
            "https://preprod-api.tbplabs.tech/chatapp/templates/nonToken",
            {
              params: {
                appAccountId,
                objectid,
              },
            }
          ),
        ]);

        const profileData = profileResponse.data;
        const templatesData = templatesResponse.data.data;

        setProfile(profileData);
        setTemplates(templatesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [appAccountId]);

  function setActiveProfile() {
    let temp = { ...tempId.current };
    temp.Data.object.conversation = "Active";
    setProfile(temp);
  }

  return (
    <div className="app">
      <Header
        accountDetails={accountDetails}
        appAccountId={appAccountId}
        setAppAccountId={setAppAccountId}
        profile={profile}
      />
      <TemplateModal
      appAccountId={appAccountId}
        openModal={modalIsOpen}
        closeModal={closeModal}
        profile={profile}
        templates={templates}
      />
      <div className="wrapper">
        {appAccountId !== undefined && (
          <ChatArea
            accountid={accountid}
            appAccountId={appAccountId}
            userid={userid}
            objectid={objectid}
            profile={profile}
            setActiveProfile={setActiveProfile}
            openModal={openModal}
          />
        )}
      </div>
    </div>
  );
};

export default Layout;
