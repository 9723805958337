import React, { useEffect, useState, useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";
import AddCommentIcon from "@mui/icons-material/AddComment";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import ImageUploadModal from "../../components/Modals/ImageUploadModal";
import axios from "axios";

const ChatFooter = ({
  ws,
  accountid,
  userid,
  objectid,
  profile,
  openModal,
  appAccountId,
}) => {
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [caption, setcaption] = useState("");
  const [isConversationActive, setIsConversationActive] = useState(false);
  const [modalImageOpen, setImageOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  let fileUrls = "";

  function closeImageModal() {
    setImageOpen(false);
    setSelectedFile([]);
    document.getElementById("imgInput").value = "";
    document.getElementById("documentInput").value = "";
    document.getElementById("videoInput").value = "";
    setFileName("");
  }
  function openImageModal() {
    //console.log("red");
    setImageOpen(true);
  }

  async function onSend() {
    setIsLoading(true);
    let reqBody = {};
    for (let i = 0; i < selectedFile.length; i++) {
      await uploadFile(i);
      reqBody = {
        action: "SendMessageToCustomer",
        data: {
          userid: userid,
          appAccountId: appAccountId,
          accountid: accountid,
          objectid: objectid,
          type: fileType,
          text: i === 0 ? caption : "",
          url: fileUrls,
        },
      };
      ws.send(JSON.stringify(reqBody));
      //console.log("message sent");
    }
    // e.preventDefault();
    setIsLoading(false);
    setcaption("");
    closeImageModal();
    fileUrls = "";
  }

  const uploadFile = async (i) => {
    const formData = new FormData();
    formData.append("file", selectedFile[i]);
    try {
      setIsLoading(true);
      const response = await axios({
        method: "post",
        url: `https://preprod-api.tbplabs.tech/chatapp/uploadFile?accountId=${accountid}&userId=${userid}&objectid=${objectid}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      //console.log(response);
      fileUrls = response.data.data.fileUrl;
    } catch (error) {
      //console.log(error);
    }
  };

  const sendMessage = {
    action: "SendMessageToCustomer",
    data: {
      text: message,
      userid: userid,
      accountid: accountid,
      objectid: objectid,
      appAccountId: appAccountId,
    },
  };

  const handleSubmit = (e) => {
    //console.log("handle submit");
    e.preventDefault();
    ws.send(JSON.stringify(sendMessage));
    //console.log(message);
    //console.log("message sent");
    setMessage("");
  };

  useEffect(() => {
    if (profile && profile.Data) {
      if (profile.Data.object.conversation === "Active") {
        setIsConversationActive(true);
      } else {
        setIsConversationActive(false);
      }
    } else {
      setIsConversationActive(false);
    }
  }, [profile]);

  const [selectedFile, setSelectedFile] = useState([]);
  useEffect(() => {
    //console.log("filelength =" + selectedFile.length);
    if (selectedFile.length > 0) {
      openImageModal();
    }
  }, [selectedFile]);

  const handleFileSelect = (event) => {
    if (event.target.id == "imgInput") {
      setFileType("image");
    } else if (event.target.id == "documentInput") {
      setFileType("document");
    } else if (event.target.id == "videoInput") {
      setFileType("video");
    }
    let files = [];
    for (let i = 0; i < event.target.files.length; i++) {
      files.push(event.target.files[i]);
    }
    setFileName(event.target.value.split(/(\\|\/)/g).pop());
    setSelectedFile([...files]);
  };

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const items = clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.type.indexOf("image") !== -1) {
        e.preventDefault();
        setFileType("image");

        const blob = item.getAsFile();

        const fileName = `pasted_image_${Date.now()}.png`;
        const file = new File([blob], fileName, { type: blob.type });

        setSelectedFile([file]);
        return;
      }
    }
  };

  return (
    <>
      <ImageUploadModal
        caption={caption}
        setCaption={setcaption}
        openModal={modalImageOpen}
        closeModal={closeImageModal}
        images={selectedFile}
        onSend={onSend}
        isLoading={isLoading}
        fileType={fileType}
        fileName={fileName}
      />

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        profile={profile}
        ws={ws}
        userid={userid}
        accountid={accountid}
        objectid={objectid}
      />
      <div className={`chat-area-footer-parent px-0 py-0`}>
        <div
          className={`chat-area-footer ${
            isConversationActive ? "" : "pointer-events-none"
          }`}
        >
          <IconButton color="primary" style={{ overflow: "hidden" }}>
            <input
              id="imgInput"
              accept="image/*"
              type="file"
              style={{ opacity: "0", position: "absolute" }}
              onChange={handleFileSelect}
              multiple
            />
            <ImageOutlinedIcon sx={{ width: "30px" }} />
          </IconButton>
          <AddCircleOutlineOutlinedIcon sx={{ width: "30px" }} />
          <IconButton
            // onClick={() => setShowModal(!showModal)}
            style={{ overflow: "hidden" }}
          >
            <input
              id="documentInput"
              type="file"
              style={{ opacity: "0", position: "absolute" }}
              onChange={handleFileSelect}
            />
            <AttachFileOutlinedIcon sx={{ width: "30px" }} />
          </IconButton>
          <IconButton style={{ overflow: "hidden" }}>
            <input
              id="videoInput"
              accept="video/*"
              type="file"
              style={{ opacity: "0", position: "absolute" }}
              onChange={handleFileSelect}
            />
            <VideoCameraBackOutlinedIcon />
          </IconButton>

          <form style={{ display: "flex" }} onSubmit={handleSubmit}>
            <textarea
              type="text"
              placeholder="Type something here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  handleSubmit(e);
                } else if (e.shiftKey && e.key === "Enter") {
                  e.preventDefault();
                  setMessage(message + "\n");
                }
              }}
              disabled={!isConversationActive}
              onPaste={handlePaste}
              className="w-full pt-2 rounded-lg"
            />
            <IconButton
              type="submit"
              disableRipple
              size="small"
              sx={{
                ml: 1,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              <SendIcon sx={{ width: "30px", ml: "10px" }} />
            </IconButton>
          </form>
        </div>
        <AddCommentIcon onClick={openModal}></AddCommentIcon>
      </div>
    </>
  );
};

export default ChatFooter;

const Modal = ({
  showModal,
  setShowModal,
  profile,
  ws,
  accountid,
  userid,
  objectid,
}) => {
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState("");
  //  let url = "";
  //  let type = "";
  // const url = useRef(null)
  // const type = useRef(null)
  const [url, setUrl] = useState();
  const [type, setType] = useState();

  let list = [];

  if (profile) {
    profile.Data.uploadedfile.map((file) => {
      list.push({
        id: file.id,
        label: file.name,
        url: file.url,
        type: file.type,
      });
    });
  }

  useEffect(() => {
    if (list) {
      list.map((file) => {
        if (value.label === file.label) {
          // url.current = file.url
          // type.current = file.type
          // //console.log(url.current)
          setUrl(file.url);
          setType(file.type);
          //console.log(type);
          //console.log(file);
        }
      });
    }
  }, [value]);

  const sendUrl = {
    action: "SendMessageToCustomer",
    data: {
      url: url,
      type: type,
      userid: userid,
      accountid: accountid,
      objectid: objectid,
    },
  };

  const handleClick = () => {
    ws.send(JSON.stringify(sendUrl));
    //console.log(JSON.stringify(sendUrl));
    setShowModal(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="absolute bottom-[70px]">
            <div className="relative mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <div className="mt-3 flex justify-center flex-col">
                <div className="mt-2 py-3">
                  <Autocomplete
                    className="w-full mx-auto"
                    disableClearable
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    disablePortal
                    options={list}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    onClick={handleClick}
                    id="ok-btn"
                    className="px-4 py-2 bg-[#0086ff] text-white text-base font-medium rounded-md w-full shadow-sm focus:outline-none"
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
