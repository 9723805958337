import React from 'react'

const TimeStamp = ({time}) => {
     const messageTime = new Date(time);
     const hours = messageTime.getHours();
     const minutes = messageTime.getMinutes();
     const day = messageTime.getDate();
     const month = messageTime.getMonth();
     const year = messageTime.getFullYear();

     const monthList = [
       "Jan",
       "Feb",
       "Mar",
       "Apr",
       "May",
       "Jun",
       "Jul",
       "Aug",
       "Sept",
       "Oct",
       "Nov",
       "Dec",
     ];
  return (
    <>
      <div className="mt-2 text-xs">
        <p>
          {hours}:{minutes}
        </p>
        <p>
          {day} {monthList[month]} {year}
        </p>
      </div>
    </>
  );
}

export default TimeStamp