import React, { useState, useRef, useEffect } from "react";
import "./chatarea.css";
import Message from "./Message/Message";
import ChatFooter from "./ChatFooter";
import { ws } from "./Connection";
import axios from "axios";

const ChatArea = ({
  accountid,
  userid,
  objectid,
  profile,
  openModal,
  openImageModal,
  setActiveProfile,
  appAccountId,
}) => {
  const [socketResponse, setSocketResponse] = useState([]);
  const [nextoffset, setNextoffset] = useState(0);
  const [waitingToReconnect, setWaitingToReconnect] = useState(null);
  const [showQuickReplies, setShowQuickReplies] = useState(false);

  let [bottomScroll, setBottomScroll] = useState(true);
  const bottomDiv = useRef(null);
  const a = useRef(null);

  const scrollToBottom = () => {
    if (bottomDiv.current && bottomScroll) {
      bottomDiv.current.scrollIntoView();
    }
  };

  const handleScroll = (e) => {
    let element = e.target;

    if (element.scrollTop === 0) {
      setBottomScroll(false);
      if (nextoffset === 0) {
        return;
      } else {
        getChatHistoryByApi();
      }
    }
  };

  async function getChatHistoryByApi() {
    try {
      const response = await axios.get(
        `https://preprod-api.tbplabs.tech/chatapp/getChatHistory?objectId=${objectid}&appAccountId=${appAccountId}&offset=${nextoffset}`
      );
      const responseData = response.data;

      if (nextoffset === 0) {
        setSocketResponse(responseData.data);
        setNextoffset(responseData.Nextoffset);
      } else {
        setSocketResponse((prevArray) => [...responseData.data, ...prevArray]);
        setNextoffset(responseData.Nextoffset);
        a.current.childNodes[responseData.data.length].scrollIntoView();
      }
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://preprod-api.tbplabs.tech/chatapp/getChatHistory?objectId=${objectid}&appAccountId=${appAccountId}&offset=0`
        );
        const responseData = response.data;

        if (nextoffset === 0) {
          setSocketResponse(responseData.data);
          setNextoffset(responseData.Nextoffset);
        } else {
          setSocketResponse((prevArray) => [
            ...responseData.data,
            ...prevArray,
          ]);
          setNextoffset(responseData.Nextoffset);
          a.current.childNodes[responseData.data.length].scrollIntoView();
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    };

    const interval = setInterval(() => {
      pingWs();
    }, MINUTE_MS);

    fetchData();

    return () => clearInterval(interval);
  }, [appAccountId]);

  const connect = () => {
    ws.onopen = function () {
      ws.send(
        JSON.stringify({
          action: "getChatHistory",
          data: {
            appAccountId: appAccountId,
            userid: userid,
            objectid: objectid,
          },
        })
      );
    };

    ws.onmessage = function (event) {
      let res = JSON.parse(event.data);
      if (res.data) {
        // if (res.data.length > 2) {
        //  setSocketResponse(res.data);
        // getChatHistoryByApi();
        //console.log("test bulk");
        // }
        if (res.data.length === 1) {
          setSocketResponse((prevArray) => [...prevArray, res.data[0]]);
          if (res.responseType === "inboundMessage") {
            setActiveProfile();
          }
          scrollToBottom();
        }
      }
    };

    ws.onclose = function (e) {
      setTimeout(function () {
        connect();
      }, 1000);
    };

    ws.onerror = function (err) {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      ws.close = function () {
        if (waitingToReconnect) {
          return;
        }

        setWaitingToReconnect(true);

        setTimeout(() => setWaitingToReconnect(null), 5000);
      };
    };
  };

  function pingWs() {
    ws.send(JSON.stringify("."));
  }

  const MINUTE_MS = 9000 * 60;

  useEffect(() => {
    scrollToBottom();
  }, [socketResponse]);

  useEffect(() => {
    if (waitingToReconnect) {
      return;
    }
    connect();
  }, [waitingToReconnect]);

  return (
    <>
      <div className="chat-area">
        <div className="chat-area-header"></div>
        <div ref={a} className="chat-area-main" onScroll={handleScroll}>
          {socketResponse &&
            socketResponse.map((e, id) => {
              return (
                <Message
                  key={id}
                  message={e.Text}
                  sentBy={e.SentBy}
                  mediaURL={e.MediaUrl}
                  messageType={e.MessageType}
                  time={e.created_at}
                  senderName={e.SenderName}
                  statuses={e.Statuses}
                />
              );
            })}
          <div ref={bottomDiv} />
        </div>
        <ChatFooter
          ws={ws}
          accountid={accountid}
          appAccountId={appAccountId}
          userid={userid}
          objectid={objectid}
          profile={profile}
          openModal={openModal}
          openImageModal={openImageModal}
          showQuickReplies={showQuickReplies}
          setShowQuickReplies={setShowQuickReplies}
        />
      </div>
    </>
  );
};

export default ChatArea;
