import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import "./header.css";
import { Circle, Person } from "@mui/icons-material";
import { Popover, Skeleton, Typography } from "@mui/material";
import axios from "axios";

const Header = ({ profile, appAccountId, setAppAccountId, accountDetails }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  

  return (
    <>

<div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="p-4 w-72">
        <h1 className="text-sky-600 font-bold mb-3">User Details</h1>
        <div className="flex flex-col gap-2">
        <p><span className="font-bold">Name: </span>{profile?.Data.object.Name}</p>
        <p><span className="font-bold">Phone: </span>{profile?.Data.object.phone}</p>
        <p><span className="font-bold">Email: </span>{profile?.Data.object.email}</p>
        <p><span className="font-bold">Company: </span>{profile?.Data.object.company}</p>

        <p><span className="font-bold">Job Title: </span>{profile?.Data.object.jobtitle}</p>
        </div>

        </div>
      </Popover>
    </div>


      <div className="header border-b-2 border-slate-200 mt-2 ">
        <div className="flex items-center">
          <div className="logo">
            <Avatar>{profile?.Data.object.Name.slice(0, 1) || "R"}</Avatar>
          </div>
          <Circle
            className="relative right-2 top-3"
            sx={
              profile?.Data.object.conversation === "Active"
                ? { color: "#17BF27", height: "16px", width: "16px" }
                : { color: "red", height: "16px", width: "16px" }
            }
          />
          <div>
            <p className="font-bold">
              {profile?.Data.object.Name || ""}{" "}
              {profile?.Data.object.phone ? (
                "(" + profile?.Data.object.phone + ")"
              ) : (
                <Skeleton variant="rectangular" width={210} height={20} />
              )}
            </p>
            <p
              className={
                profile?.Data.object.conversation === "Active"
                  ? "text-sm font-bold text-green-600"
                  : "text-sm font-bold text-red-600"
              }
            >
              {profile?.Data.object.conversation}{" "}
              <span className="text-black text-[12px]">
                {profile?.Data.object.conversationToExpireIn === undefined ? (
                  <Skeleton width={300} height={25} />
                ) : (
                  "(" + profile?.Data.object.conversationToExpireIn + ")"
                )}
              </span>
            </p>
          </div>
        </div>
                 
        <div className="user-settings">
          
        <select className="border mr-2 h-8 rounded-lg cursor-pointer text-sm text-sky-600 px-3 font-semibold" onChange={(e)=>setAppAccountId(e.target.value)}>
        {accountDetails?.apps.map((e)=>(
            <option value={e.appAccount_id}>{e.name}</option>
            ))}
        </select>
          <Person aria-describedby={id} variant="contained" onClick={handleClick} sx={{ color: "gray" }} />

          <div className="settings">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="3" />
              <path d="M19.4 15a1.65 1.65 0 00.33 1.82l.06.06a2 2 0 010 2.83 2 2 0 01-2.83 0l-.06-.06a1.65 1.65 0 00-1.82-.33 1.65 1.65 0 00-1 1.51V21a2 2 0 01-2 2 2 2 0 01-2-2v-.09A1.65 1.65 0 009 19.4a1.65 1.65 0 00-1.82.33l-.06.06a2 2 0 01-2.83 0 2 2 0 010-2.83l.06-.06a1.65 1.65 0 00.33-1.82 1.65 1.65 0 00-1.51-1H3a2 2 0 01-2-2 2 2 0 012-2h.09A1.65 1.65 0 004.6 9a1.65 1.65 0 00-.33-1.82l-.06-.06a2 2 0 010-2.83 2 2 0 012.83 0l.06.06a1.65 1.65 0 001.82.33H9a1.65 1.65 0 001-1.51V3a2 2 0 012-2 2 2 0 012 2v.09a1.65 1.65 0 001 1.51 1.65 1.65 0 001.82-.33l.06-.06a2 2 0 012.83 0 2 2 0 010 2.83l-.06.06a1.65 1.65 0 00-.33 1.82V9a1.65 1.65 0 001.51 1H21a2 2 0 012 2 2 2 0 01-2 2h-.09a1.65 1.65 0 00-1.51 1z" />
            </svg>
          </div>

          {/* <img className="user-profile" src="" alt="" /> */}
        </div>
      </div>
    </>
  );
};

export default Header;
