import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './Layout';
import IdTemplate from './screen/IdTemplate';

function App() {
  return (
    <Router>
     
        <Switch>
          <Route exact path="/" component={Layout} /> 
          <Route path="/id-template" component={IdTemplate} /> 
        </Switch>
     
    </Router>
  );
}

export default App;
