import { Label } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import useQuery from "../../hooks/useQuery";
import { ws } from "../ChatArea/Connection";
import Modal from "react-modal";
import axios from "axios";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";

Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "70vh",
    position: "relative",
    padding: "0px 0px",
    maxWidth: "60rem",
  },
  overlay: {
    backgroundColor: "rgba(1,1,1,0.4)",
    backdropFilter: "blur(4px)",
    zIndex: 10000,
  },
  dropdown: {
    border: "1px solid gainsboro",
    padding: "4px",
    borderRadius: "7px",
    width: "90%",
  },
  textBox: {
    border: "1px solid gainsboro",
    padding: "4px",
    borderRadius: "7px",
    width: "40%",
  },
  inputRow: {
    display: "flex",
    justifyContent: "space-evenly",
  },
};

const TemplateModal = (props) => {
  const query = useQuery();
  const [accountid, setAccountId] = useState();
  const [appAccountId, setAppAccountId] = useState();
  const accountidURL = query.get("accountidURL");
  const userid = query.get("userid");
  const objectid = query.get("objectid");
  const { openModal, closeModal } = props;
  const [templateIndex, setTemplateIndex] = useState("null");
  const [bodyT, setBodyT] = useState("");
  const [footerT, setFooterT] = useState("");
  const [headerVariable, setHeaderVariable] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [variables, setVariables] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [webButtonVariableValue, setWebButtonVariableValue] = useState();

  let subtitle;
  let headerUrl = "";
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  async function getAccountID() {
    if (!accountidURL) {
      return;
    }
    const api = {
      url: `https://preprod-api.tbplabs.tech/chatapp/publicGetChatApps?accountId=${accountidURL}&userId=${userid}`,
    };
    const resp = await axios.get(api.url);
    setAccountId(resp?.data.apps[0].id);
    setAppAccountId(resp?.data.apps[0].appAccount_id);
  }

  useEffect(() => {
    getAccountID();
  }, []);

  function onTemplateSelect(e) {
    // setHeaderVariable('')
    //console.log(e.target.value);
    setTemplateIndex(e.target.value);
    let index = e.target.value;
    if (index != "null") {
      //console.log(props.templates[index]);
      setSelectedTemplate(props.templates[index]);
      setHeaderVariable(props.templates[index].headerVariableValue);
      let varData = [];
      let body = "";
      let footer = "";
      //console.log(props.templates[index].components);
      for (let i = 0; i < props.templates[index].components.length; i++) {
        if (props.templates[index].components[i].type === "BODY") {
          body = props.templates[index].components[i].text;
        }
        if (props.templates[index].components[i].type === "FOOTER") {
          footer = props.templates[index].components[i].text;
        }
      }
      //console.log(body);
      setBodyT(body);
      setFooterT(footer);
      for (let i = 1; i <= props.templates[index].variableCount; i++) {
        varData.push("");
      }
      for (let i = 0; i < props.templates[index].variableValues.length; i++) {
        varData[props.templates[index].variableValues[i].var - 1] =
          props.templates[index].variableValues[i].value;
        // //console.log(props.templates[index].variableValues)
      }
      setVariables(varData);
    } else {
      setBodyT("");
      setFooterT("");
      setSelectedTemplate(null);
    }
  }
  async function onSend() {
    setIsLoading(true);
    if (
      selectedTemplate.headerMediaType != "TEXT" &&
      selectedTemplate.headerMediaType != "" &&
      selectedFile != null
    ) {
      await uploadFile();
    }
    let reqBody = {
      action: "SendMessageToCustomer",
      data: {
        userid: userid,
        appAccountId: props.appAccountId,
        objectid: objectid,
        templateId: selectedTemplate.templateId,
        variables: variables,
        headerMediaType: selectedTemplate.headerMediaType,
        headerMediaURL: headerUrl,
        headerText: headerVariable,
        webVariableValue: webButtonVariableValue,
      },
    };

    //console.log(reqBody);
    //console.log("handle submit");
    // e.preventDefault();
    ws.send(JSON.stringify(reqBody));
    //console.log("message sent");
    setIsLoading(false);
    closeModal();
    setSelectedTemplate(null);
    setTemplateIndex("null");
    setHeaderVariable("");
  }

  function changeVariableValue(e, varNo) {
    let temp = [...variables];
    temp[varNo] = e.target.value;
    //console.log(temp);
    setVariables(temp);
  }

  const [selectedFile, setSelectedFile] = useState(null);

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      setIsLoading(true);
      const response = await axios({
        method: "post",
        url: `https://preprod-api.tbplabs.tech/chatapp/uploadFile?accountId=${accountid}&userId=${userid}&objectid=${objectid}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      //console.log(response);
      headerUrl = response.data.data.fileUrl;
    } catch (error) {
      //console.log(error);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex w-full justify-between items-center h-[10%] px-[16px]">
          <div className="font-bold">
            Send Template Message to Contact
            {props.profile != null ? props.profile.Data.object.phone : null}
          </div>
          <div className="cursor-pointer" onClick={closeModal}>
            x
          </div>
        </div>
        <hr />
        <div className="overflow-y-auto flex w-full justify-around items-center relative h-[80%] px-[1rem] py-[1rem]">
          <div
            className="circle_cont"
            style={{
              display: isLoading ? "flex" : "none",
            }}
          >
            <svg
              class="svg"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="50"
                cy="50"
                r="30"
                fill="transparent"
                stroke-width="8px"
                stroke-dasharray="160"
              />
            </svg>
          </div>
          <div className="self-start">
            <div className="font-semibold py-[0.62rem]">Select Template</div>
            <select
              style={customStyles.dropdown}
              value={templateIndex}
              onChange={(e) => {
                onTemplateSelect(e);
              }}
              className="my-[0.62rem]"
            >
              <option value={"null"}>Select Template</option>
              {props.templates?.map((template, index) => (
                <option value={index}>{template.name}</option>
              ))}
            </select>
            <div
              className="temp-variables"
              style={{ display: templateIndex !== "null" ? "block" : "none" }}
            >
              {variables.map((variable, index) => (
                <div style={customStyles.inputRow} className="my-[0.62rem]">
                  <label>Var {index + 1} </label>
                  <input
                    type="text"
                    value={variables[index]}
                    onChange={(e) => {
                      changeVariableValue(e, index);
                    }}
                    onClick="this.select();"
                    style={customStyles.textBox}
                  />
                </div>
              ))}
              <div>
                {selectedTemplate &&
                  selectedTemplate.webButtonVariableCount === 1 && (
                    <div className="flex items-center gap-6 mt-2 pl-1">
                      <p className="w-36">Web Button:</p>
                      <input
                        className="w-10"
                        type="text"
                        name="webButtonVarible"
                        style={customStyles.dropdown}
                        onChange={(e) =>
                          setWebButtonVariableValue(e.target.value)
                        }
                      />
                    </div>
                  )}
              </div>
              <div
                style={{
                  display:
                    selectedTemplate &&
                    selectedTemplate.headerMediaType !== "TEXT" &&
                    selectedTemplate.headerMediaType !== ""
                      ? "block"
                      : "none",
                }}
              >
                <div className="font-semibold py-[0.62rem]">
                  Select Header{" "}
                  {selectedTemplate &&
                  selectedTemplate.headerMediaType == "IMAGE"
                    ? "Image"
                    : selectedTemplate &&
                      selectedTemplate.headerMediaType == "VIDEO"
                    ? "Video"
                    : "File"}
                </div>
                <input
                  type="file"
                  onChange={handleFileSelect}
                  accept={
                    selectedTemplate &&
                    selectedTemplate.headerMediaType == "IMAGE"
                      ? "image/*"
                      : selectedTemplate &&
                        selectedTemplate.headerMediaType == "VIDEO"
                      ? "video/*"
                      : ""
                  }
                />
              </div>
              <div
                style={{
                  display:
                    selectedTemplate && selectedTemplate.headerVariable == true
                      ? "block"
                      : "none",
                }}
              >
                <div className="font-semibold py-[0.62rem]">
                  Header Variable
                </div>
                <input
                  type="text"
                  value={headerVariable}
                  style={customStyles.textBox}
                  onChange={(e) => {
                    setHeaderVariable(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="preview-template w-2/5">
            <div class="bgImg">
              <div className="chat-bubbles">
                {selectedTemplate &&
                selectedTemplate.headerMediaType == "IMAGE" ? (
                  <div className="sample-image">
                    <ImageIcon></ImageIcon>
                  </div>
                ) : null}
                {selectedTemplate &&
                selectedTemplate.headerMediaType == "DOCUMENT" ? (
                  <div className="sample-file">
                    <InsertDriveFileIcon></InsertDriveFileIcon>
                  </div>
                ) : null}
                {selectedTemplate &&
                selectedTemplate.headerMediaType == "VIDEO" ? (
                  <div className="sample-file">
                    <VideoCameraBackIcon></VideoCameraBackIcon>
                  </div>
                ) : null}
                <div className="mb-[0.62rem]">{bodyT}</div>
                <div className="text-gray-400">{footerT}</div>
                <div className="bubble-arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="flex w-full justify-end items-center h-[10%] px-[1rem]">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-4 rounded modlBtn"
            onClick={() => {
              onSend();
            }}
          >
            Send
          </button>
          <button
            class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow modlBtn"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default TemplateModal;
