import React, { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import TimeStamp from "./TimeStamp";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";

const getName = (mediaURL) => {
  let name = mediaURL.split("/");
  name = name[name.length - 1];
  return name;
};

const eventMessageStyle={
  backgroundColor: "#F4F7AC",
  color:"#404301",
  padding:"5px 15px",
  borderRadius:"10px",
  margin:"auto",
  fontWeight:"600",
  fontSize:"small"
}


function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text?.replace(urlRegex, function (url) {
    return `<a  target="_blank"
          rel="noopener noreferrer" style="color: skyBlue" href="${url}">${url}</a>`;
  });
}

const Message = ({
  message,
  sentBy,
  mediaURL,
  messageType,
  time,
  senderName,
  statuses,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const openFullScreen = () => {
    setIsFullScreen(true);
  };

  const closeFullScreen = () => {
    setIsFullScreen(false);
  };

  const handleBackdropClick = () => {
    closeFullScreen();
  };
  let status = {
    read: false,
    sent: false,
    delivered: false,
  };

  let tooltiptext = {
    width: "max-content",
    backgroundColor: "black",
    color: "#fff",
    textAlign: "center",
    borderRadius: "6px",
    padding: "5px 10px",

    /* Position the tooltip */
    position: "absolute",
    zIndex: "1",
    top: "-5px",
    right: "105%",
  };

  if (sentBy === "agent") {
    // //console.log(statuses)

    for (let i = 0; i < statuses?.length; i++) {
      let temp = { ...status };
      temp[statuses[i].eventType] = true;
      status = temp;
    }
  }

const messageWithBr = message?.replace(/\n/g, '<br>');
const clickableMessage = urlify(messageWithBr)

  return (
    <>
      <div
        className={`chat-msg ${sentBy !== "user" && "owner"} ${
          messageType === "event" ? "event" : ""
        }`}
      >
        <div className="chat-msg-profile"></div>
        <div className="chat-msg-content">
          {message && messageType !== "event" && messageType !=="image" ? (
            <>
              <div className="chat-msg-text">
                <p
                  dangerouslySetInnerHTML={{ __html: clickableMessage }}
                  className="pb-3 text-base"
                  style={{ fontSize: "0.9rem" }}
                >
                </p>

                <hr className="" />
                <TimeStamp time={time} sentBy={sentBy} />
              </div>
              <div className="text-[#c0c7d2] text-sm mt-1">
                {sentBy === "agent" ? (
                  <span className="tooltip">
                    {statuses?.read.isPresent ? (
                      <DoneAllIcon style={{ color: "#0086ff" }} />
                    ) : statuses?.delivered.isPresent ? (
                      <DoneAllIcon />
                    ) : statuses?.sent.isPresent ? (
                      <DoneIcon />
                    ) : statuses?.failed.isPresent ? (
                      <ErrorOutlineIcon style={{ color: "red" }} />
                    ) : null}
                    <span style={tooltiptext} className="tooltiptext">
                      {statuses?.read.isPresent ? (
                        <>
                          Read{" "}
                          {new Date(statuses?.read.created_at).toLocaleString()}{" "}
                          <br />
                        </>
                      ) : null}
                      {statuses?.delivered.isPresent ? (
                        <>
                          Delivered{" "}
                          {new Date(
                            statuses?.delivered.created_at
                          ).toLocaleString()}{" "}
                          <br />
                        </>
                      ) : null}
                      {statuses?.sent.isPresent ? (
                        <>
                          Sent{" "}
                          {new Date(statuses?.sent.created_at).toLocaleString()}{" "}
                          <br />
                        </>
                      ) : null}
                      {statuses?.failed.isPresent ? (
                        <>
                          Failed{" "}
                          {new Date(
                            statuses?.failed.created_at
                          ).toLocaleString()}{" "}
                          <br />
                        </>
                      ) : null}
                    </span>
                  </span>
                ) : null}
                &nbsp; {senderName}
              </div>
            </>
          ) : (
            message && messageType !=="image" && (
              <p style={eventMessageStyle} className="event-message">
                {message}
              </p>
            )
          )}
          {mediaURL && messageType === "image" && (
            <>
            <div className="chat-msg-text">
              <img
                className="cursor-pointer"
                src={mediaURL}
                onClick={openFullScreen}
                alt="img"
              />
              <p className="pt-2 pb-2">
              {message}
              </p>
              <hr className="" />
              <TimeStamp time={time} sentBy={sentBy} />
              
            </div>

            <div className="text-[#c0c7d2] text-sm mt-1">
                {sentBy === "agent" ? (
                  <span className="tooltip">
                    {statuses?.read.isPresent ? (
                      <DoneAllIcon style={{ color: "#0086ff" }} />
                    ) : statuses?.delivered.isPresent ? (
                      <DoneAllIcon />
                    ) : statuses?.sent.isPresent ? (
                      <DoneIcon />
                    ) : statuses?.failed.isPresent ? (
                      <ErrorOutlineIcon style={{ color: "red" }} />
                    ) : null}
                    <span style={tooltiptext} className="tooltiptext">
                      {statuses?.read.isPresent ? (
                        <>
                          Read{" "}
                          {new Date(statuses?.read.created_at).toLocaleString()}{" "}
                          <br />
                        </>
                      ) : null}
                      {statuses?.delivered.isPresent ? (
                        <>
                          Delivered{" "}
                          {new Date(
                            statuses?.delivered.created_at
                          ).toLocaleString()}{" "}
                          <br />
                        </>
                      ) : null}
                      {statuses?.sent.isPresent ? (
                        <>
                          Sent{" "}
                          {new Date(statuses?.sent.created_at).toLocaleString()}{" "}
                          <br />
                        </>
                      ) : null}
                      {statuses?.failed.isPresent ? (
                        <>
                          Failed{" "}
                          {new Date(
                            statuses?.failed.created_at
                          ).toLocaleString()}{" "}
                          <br />
                        </>
                      ) : null}
                    </span>
                  </span>
                ) : null}
                &nbsp; {senderName}
              </div>
            </>
          )}
          {mediaURL && messageType === "video" && (
            <video controls width="320" height="240">
              <source src={mediaURL} type="video/mp4" />
            </video>
          )}
          {mediaURL && messageType === "audio" && (
            <audio controls>
              <source src={mediaURL} type="audio/mpeg"></source>
            </audio>
          )}
          {mediaURL && messageType === "pdf" && (
            <div className="chat-msg-text">
              <div className="pdf">
                <img
                  src="https://img.icons8.com/color/96/000000/pdf-2--v1.png"
                  alt="pdf"
                />
                <span className="text-left">{getName(mediaURL)}</span>
                <a href={mediaURL} target="_blank" rel="noreferrer">
                  <DownloadIcon />
                </a>
              </div>
              <TimeStamp time={time} sentBy={sentBy} />
            </div>
          )}
          {mediaURL && messageType === "docx" && (
            <div className="chat-msg-text">
              <div className="pdf">
                <img
                  src="https://img.icons8.com/color/96/000000/pdf-2--v1.png"
                  alt="pdf"
                />
                <span className="text-left">{getName(mediaURL)}</span>
                <a href={mediaURL} target="_blank" rel="noreferrer">
                  <DownloadIcon />
                </a>
              </div>
              <TimeStamp time={time} sentBy={sentBy} />
            </div>
          )}
        </div>
      </div>
      <Modal open={isFullScreen} onClose={closeFullScreen}>
        <Backdrop open={isFullScreen} onClick={handleBackdropClick}>
          <div className="flex justify-center items-center w-screen h-screen">
            <img
              src={mediaURL}
              alt="Full-screen"
              className="max-h-full max-w-full"
            />
          </div>
        </Backdrop>
      </Modal>
    </>
  );
};

export default Message;
